var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _c("vx-card", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mb-2" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Year.i18n) ||
                          _vm.resources.Year.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      options: _vm.yearList,
                      reduce: function(item) {
                        return item
                      },
                      label: "item"
                    },
                    model: {
                      value: _vm.year,
                      callback: function($$v) {
                        _vm.year = $$v
                      },
                      expression: "year"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mb-2" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Month.i18n) ||
                          _vm.resources.Month.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      options: _vm.monthList,
                      reduce: function(item) {
                        return item
                      },
                      label: "item"
                    },
                    model: {
                      value: _vm.month,
                      callback: function($$v) {
                        _vm.month = $$v
                      },
                      expression: "month"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col mb-2 mt-5" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Search.i18n) ||
                            _vm.resources.Search.name
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c("vue-apex-charts", {
                  attrs: {
                    type: "line",
                    height: "350",
                    options: _vm.mixedChart.chartOptions,
                    series: _vm.mixedChart.series
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row mb-2" }, [
      _c(
        "div",
        { staticClass: "vx-col lg:w-1/2 w-full" },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-2",
              attrs: {
                title:
                  _vm.$t(_vm.resources.Sales.i18n) || _vm.resources.Sales.name
              }
            },
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "pie",
                  height: "350",
                  options: _vm.pieChart.chartOptions,
                  series: _vm.pieChart.series
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col lg:w-1/2 w-full" },
        [
          _c(
            "vx-card",
            {
              attrs: {
                title:
                  _vm.$t(_vm.resources.LatestBookings.i18n) ||
                  _vm.resources.LatestBookings.name
              }
            },
            [
              _c(
                "vs-table",
                {
                  staticClass: "vx-col w-full",
                  attrs: { data: _vm.lastestBooking },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].productName } },
                                [_vm._v(_vm._s(data[indextr].productName))]
                              ),
                              _c(
                                "vs-td",
                                {
                                  attrs: {
                                    data: data[indextr].bookingReference
                                  }
                                },
                                [_vm._v(_vm._s(data[indextr].bookingReference))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].total } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(data[indextr].total) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].createdDate } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        data[indextr].createdDate.substring(
                                          0,
                                          10
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        })
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Type.i18n) ||
                                _vm.resources.Type.name
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Code.i18n) ||
                                _vm.resources.Code.name
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Total.i18n) ||
                                _vm.resources.Total.name
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.CreationDate.i18n) ||
                                _vm.resources.CreationDate.name
                            ) +
                            "\n            "
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row mb-2" }, [
      _c(
        "div",
        { staticClass: "vx-col lg:w-1/2 w-full" },
        [
          _c(
            "vx-card",
            {
              attrs: {
                title:
                  _vm.$t(_vm.resources.TopTravelAgents.i18n) ||
                  _vm.resources.TopTravelAgents.name
              }
            },
            [
              _c(
                "vs-table",
                {
                  staticClass: "vx-col w-full",
                  attrs: { data: _vm.topSellers },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].itaCode } },
                                [_vm._v(_vm._s(data[indextr].itaCode))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].itaName } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(data[indextr].name) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                {
                                  attrs: { data: data[indextr].itaCommission }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(data[indextr].itaCommission) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].bvPoints } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(data[indextr].bvPoints) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        })
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.DistributorID.i18n) ||
                                _vm.resources.DistributorID.name
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Name.i18n) ||
                                _vm.resources.Name.name
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Value.i18n) ||
                                _vm.resources.Value.name
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.BVPoints.i18n) ||
                                _vm.resources.BVPoints.name
                            ) +
                            "\n            "
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }