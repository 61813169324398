<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card class="mb-4">
          <div class="vx-row">
            <div class="vx-col md:w-1/6 w-full mb-2">
              <label>{{$t(resources.Year.i18n) || resources.Year.name}}</label>
              <v-select
                v-model="year"
                :clearable="false"
                :options="yearList"
                :reduce="item => item"
                label="item"
              />
            </div>
            <div class="vx-col md:w-1/6 w-full mb-2">
              <label>{{$t(resources.Month.i18n) || resources.Month.name}}</label>
              <v-select
                v-model="month"
                :clearable="false"
                :options="monthList"
                :reduce="item => item"
                label="item"
              />
            </div>
            <div class="vx-col mb-2 mt-5">
              <vs-button
                color="primary"
                type="filled"
                class="mr-1"
                @click="search()"
              >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button>
            </div>
          </div>
          <div class="vx-col w-full mb-2">
            <vue-apex-charts
              type="line"
              height="350"
              :options="mixedChart.chartOptions"
              :series="mixedChart.series"
            ></vue-apex-charts>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card class="mb-2" :title="$t(resources.Sales.i18n) || resources.Sales.name">
          <vue-apex-charts
            type="pie"
            height="350"
            :options="pieChart.chartOptions"
            :series="pieChart.series"
          ></vue-apex-charts>
        </vx-card>
      </div>
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card :title="$t(resources.LatestBookings.i18n) || resources.LatestBookings.name">
          <vs-table :data="lastestBooking" class="vx-col w-full">
            <template slot="thead">
              <vs-th>
                {{
                $t(resources.Type.i18n) || resources.Type.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Code.i18n) || resources.Code.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Total.i18n) || resources.Total.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.CreationDate.i18n) || resources.CreationDate.name
                }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].productName">{{ data[indextr].productName }}</vs-td>
                <vs-td :data="data[indextr].bookingReference">{{ data[indextr].bookingReference }}</vs-td>
                <vs-td :data="data[indextr].total">
                  {{
                  data[indextr].total
                  }}
                </vs-td>
                <vs-td :data="data[indextr].createdDate">
                  {{
                  data[indextr].createdDate.substring(0,10)
                  }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card :title="$t(resources.TopTravelAgents.i18n) || resources.TopTravelAgents.name">
          <vs-table :data="topSellers" class="vx-col w-full">
            <template slot="thead">
              <vs-th>
                {{
                $t(resources.DistributorID.i18n) || resources.DistributorID.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Name.i18n) || resources.Name.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Value.i18n) || resources.Value.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.BVPoints.i18n) || resources.BVPoints.name
                }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].itaCode">{{ data[indextr].itaCode }}</vs-td>
                <vs-td :data="data[indextr].itaName">
                  {{
                  data[indextr].name
                  }}
                </vs-td>
                <vs-td :data="data[indextr].itaCommission">
                  {{
                  data[indextr].itaCommission
                  }}
                </vs-td>
                <vs-td :data="data[indextr].bvPoints">
                  {{
                  data[indextr].bvPoints
                  }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import vSelect from "vue-select";
import * as lang from "vuejs-datepicker/src/locale";

export default {
  components: {
    VueApexCharts,
    "v-select": vSelect
  },
  data() {
    return {
      lang: lang,
      resources: resources,

      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      year: new Date().getFullYear(),
      month:
        new Date().getMonth() + 1 < 10
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1,
      yearList: [],
      monthList: [],
      lastestBooking: [],
      topSellers: [],
      sales: [],
      bookingYear: [],
      themeColors: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
      pieChart: {
        series: [0],
        chartOptions: {
          labels: [""],
          colors: this.themeColors,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        }
      },
      mixedChart: {
        series: [
          {
            name: `${new Date().getFullYear()}-${
              new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
            }`,
            type: "column",
            data: [23, 11, 50, 27, 13, 22, 37, 21, 44, 22, 30, 10]
          },
          {
            name: `${new Date().getFullYear() - 1}-${
              new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
            }`,
            type: "area",
            data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 0]
          }
        ],
        chartOptions: {
          colors: this.themeColors,
          chart: {
            stacked: false,
            id: "bookingByYear"
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth"
          },
          plotOptions: {
            bar: {
              columnWidth: "50%"
            }
          },
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100]
            }
          },
          labels: lang[this.$i18n.locale]._months,
          markers: {
            size: 0
          },
          xaxis: {
            type: "string"
          },
          yaxis: {
            title: {
              text: this.$i18n.t("Bookings")
            },
            min: 0
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function(y) {
                if (typeof y !== "undefined") {
                  return y.toFixed(0);
                }
                return y;
              }
            }
          }
        }
      }
    };
  },
  async created() {
    this.getYearMonth();
    this.$vs.loading();

    await this.getBookings();
    await this.getSummary();

    this.$vs.loading.close();
  },
  computed: {},
  methods: {
    async search() {
      this.$vs.loading();

      await this.getBookings();
      await this.getSummary();

      this.$vs.loading.close();
    },

    async getBookings() {
      await axios({
        method: "POST",
        url: process.env.VUE_APP_APIVT + "Booking/ListByFilters",
        data: {
          searchKeys: {
            itaCode: this.itaCode
          },
          page: 1,
          rows: 10,
          language: this.$i18n.locale.toUpperCase(),
          orderBy: "FechaCreacion-DESC"
        },
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.lastestBooking = [];
          } else {
            this.lastestBooking = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.lastestBooking = [];
        }
      );
    },

    async getSummary() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}Booking/getSummary`,
        data: {
          itaCode: this.itaCode,
          initialPeriod: `${this.year - 1}${this.month}`,
          finalPeriod: `${this.year}${this.month}`,
          language: this.$i18n.locale
        },
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.topSellers = [];
            this.sales = [];
          } else {
            this.topSellers = result.data.topSellers;
            this.sales = result.data.sales;
            this.bookingYear = result.data.lastYear;
            this.setSalesChart();
            // this.setBookingYear();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.lastestBooking = [];
        }
      );
    },

    async getYearMonth() {
      let currentDate = new Date();
      let less5 = currentDate.getFullYear() - 5;

      for (var i = 0; i <= 10; i++) {
        this.yearList.push(less5 + i);
      }

      for (var j = 0; j < 12; j++) {
        let month = j + 1 < 10 ? `0${j + 1}` : j + 1;
        this.monthList.push(month);
      }
    },

    setSalesChart() {
      let products = [];
      let percents = [];
      if (this.sales.length > 0) {
        this.sales.forEach(item => {
          products.push(item.productname);
          percents.push((item.amount * 100) / item.totalBooking);
        });
      } else {
        products = [this.$i18n.t("WithoutInformation")];
        percents = [100];
      }
      this.pieChart = {
        series: percents,
        chartOptions: {
          chart: {
            id: "productSale"
          },
          labels: products,
          colors: this.themeColors,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        }
      };
    }

    // setBookingYear() {
    //   let currentYear = [];
    //   let lastYear = [];

    //   let listCurrent = this.bookingYear.filter(x => x.year == this.year);
    //   let listLast = this.bookingYear.filter(x => x.year == this.year - 1);

    //   for (let i = 0; i < 13; i++) {
    //     let count = i + 1;
    //     if (listLast[i] != undefined) {
    //       if (listLast[i].month == count) lastYear.push(listLast[i].amount);
    //     } else lastYear.push(0);
    //   }

    //   for (let j = 0; j < 13; j++) {
    //     let count = j + 1;
    //     if (listCurrent[j] != undefined) {
    //       if (listCurrent[j].month == count)
    //         currentYear.push(listCurrent[j].amount);
    //     } else currentYear.push(0);
    //   }

    //   console.log(currentYear);
    //   console.log(lastYear);

    //   ApexCharts.exec("bookingByYear", "updateSeries", [
    //     {
    //       name: `${this.year - 1}-${this.month}`,
    //       data: lastYear
    //     },
    //     {
    //       name: `${this.year}-${this.month}`,
    //       data: currentYear
    //     }
    //   ]);
    // }
  }
};
</script>
